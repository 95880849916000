import {Article} from 'components/Article';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {RichText} from 'prismic-reactjs';
import React from 'react';
import styled from 'styled-components';
import {PageQuery} from '../../types/graphql-types';
import {ScreenSizes} from '../../types/responsive';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {setMomentLocale} from 'utils/helpers';
import {BlogLocale} from '../../types/BlogTypes';
import ArrowRightSvg from 'images/arrow-right.svg';

const BlogAuthor = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 0 2rem;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 2rem 2rem 2rem 0;
  img {
    grid-row-start: 1;
    grid-row-end: 3;
    border: 2px solid transparent;
    margin: 0;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(-135deg, rgb(172, 182, 229) 0%, rgb(116, 235, 213) 100%);
  }
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    grid-template-columns: 15% 85%;
    gap: 1rem;
    img {
      grid-row-start: 1;
      grid-row-end: 2;
    }
    h3 {
      align-self: center;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    p {
      grid-row-start: 2;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;
const H5 = styled.h5`
  margin: 1.25rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const SidebarList = styled.ul`
  color: #475157;
  margin: 0;

  > li {
    color: inherit;
  }

  > li:not(:last-child) {
    padding: 0 0 1rem;
    border-bottom: 1px solid #e3e7e8;
  }
`;

const SidebarElement = styled.li`
  > a {
    color: inherit;
  }

  list-style: none;

  &:hover {
    color: #30373b;
  }
`;

const SidebarLiBody = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  @media (max-width: ${ScreenSizes.lg}) {
    font-size: 14px;
    line-height: 22px;
  }
`;
const SidebarLink = styled(Link)`
  display: flex;
  gap: 0.5rem;

  > * {
    color: inherit;
  }
`;
const SidebarSection = styled.div`
  margin: 42px 24px 0;
  z-index: 1;

  > img {
    margin: 0;
  }
`;
const Image = styled.img``;

const Author: React.FC<PageProps<PageQuery>> = ({data, pageContext, path, uri}) => {
  const doc = data?.prismic?.allAuthors?.edges?.slice(0, 1).pop();

  if (!doc) return null;

  const authorPosts = data.prismic.allPosts?.edges
    .filter((post: any) => post.node.author_card?._meta?.uid === doc.node._meta.uid)
    .slice(0, 5);
  const page = doc.node;
  const imageUrl = page.author_image?.url;
  const authorName = page.author_name ? page.author_name[0].text : '';
  const authorDescription = page.author_description ? page.author_description[0].text : '';

  return (
    <>
      <SEO
        path={path}
        title={authorName}
        description={authorDescription}
        bodyAttributes={{class: 'author'}}
        preview={imageUrl}
      />
      <Article>
        <BlogAuthor>
          <img src={imageUrl} alt={authorName} />
          <h3 style={{marginBottom: 10}}>{authorName}</h3>
          <p>{authorDescription}</p>
        </BlogAuthor>
        <SidebarSection>
          <H5>
            <Trans>Last published posts</Trans>
          </H5>
          <SidebarList>
            {authorPosts.map((post: any, index: any) => (
              <SidebarElement key={index}>
                <SidebarLink to={'/blog/' + post.node._meta.uid}>
                  <SidebarLiBody>{post.node.title[0].text}</SidebarLiBody>
                  <Image src={ArrowRightSvg} />
                </SidebarLink>
              </SidebarElement>
            ))}
          </SidebarList>
        </SidebarSection>
      </Article>
    </>
  );
};

export default Author;

export const query = graphql`
  query Author($uid: String, $lang: String) {
    locales: allLocale(filter: {ns: {in: ["common"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic {
      allAuthors(uid: $uid, lang: $lang) {
        edges {
          node {
            author_name
            author_description
            author_image
            _meta {
              uid
              lang
            }
          }
        }
      }
      allPosts(sortBy: meta_firstPublicationDate_DESC, lang: $lang) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            title
            author_card {
              ... on PRISMIC_Author {
                author_name
                _meta {
                  uid
                }
              }
            }
            _meta {
              uid
              lang
              alternateLanguages {
                lang
              }
              tags
              firstPublicationDate
            }
          }
        }
      }
    }
  }
`;
